import firebase from 'firebase/app';
import '@firebase/messaging';
import '@firebase/analytics';

let messaging;

const config = {
  apiKey: "AIzaSyBUL0xHwoiNXJCOhKjkQYkM4CI_IVq9_9Q",
  authDomain: "farmaciaoffisana.firebaseapp.com",
  projectId: "farmaciaoffisana",
  storageBucket: "farmaciaoffisana.appspot.com",
  messagingSenderId: "753401833224",
  appId: "1:753401833224:web:61faf7c6fddca772302ebd",
  measurementId: "G-RBT7L878YT"
};

firebase.initializeApp(config);

if(firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey('BB8ksw36Nl5v9hyHg1Qlw_A9wpkLPgW9FZn1rb0hVqpTE_UqE-XZRO3p4ES3W4y__sKaCPEkRqGyZXp_2SEEUQ8');
}

export {
  messaging
};